import React, { useEffect, useState } from 'react'

import icon from '@assets/icons/icon.large.svg'
import { MetaProps } from '@components'

import { Helmet } from 'react-helmet'

const defaultTitle = 'Firgal'

export const Meta: React.FC<MetaProps> = ({ title: _title, description }) => {
  const [title, setTitle] = useState(defaultTitle)

  useEffect(() => {
    if (_title) {
      setTitle(`${_title} - ${defaultTitle}`)
    } else {
      setTitle(defaultTitle)
    }
  }, [_title])

  return (
    <Helmet>
      {/* Title Meta Tags */}
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta name="twitter:title" content={title} />

      <meta property="og:type" content="website" />

      {/* Url Meta Tags */}
      <meta property="twitter:domain" content={window.location.host} />
      <meta property="twitter:url" content={window.location.href} />
      <meta property="og:url" content={window.location.href} />

      {/* Description Meta Tags */}
      {description && <meta name="description" content={description} />}
      {description && <meta name="og:description" content={description} />}
      {description && <meta name="twitter:description" content={description} />}

      {/* Illustration Meta Tags */}
      <meta property="og:image" content={icon} />
      <meta name="twitter:card" content={icon} />
      <meta name="twitter:image" content={icon} />
    </Helmet>
  )
}

import React, { useState } from 'react'

import { classNames } from '@firgal/components'

import { Meta } from '@components'
import { RadioGroup } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'

import { useTranslation } from 'react-i18next'

type PricingType = {
  name: string
  id: string
  price: { monthly: string; annually: string }
  description: string
  features: string[]
  mostPopular: boolean
}[]

type FrequencyType = { value: 'monthly' | 'annually'; label: string; priceSuffix: string }

const frequencyOptions: FrequencyType[] = [
  { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
  { value: 'annually', label: 'Annually', priceSuffix: '/year' },
]

const tiers: PricingType = [
  {
    name: 'Clutches',
    id: 'clutches',
    price: { monthly: 'XX', annually: 'XXX' },
    description: 'Track your clutches',
    features: ['Visualize all your clutches day by day', 'Manage your henhouses', 'Get statistics about your clutches'],
    mostPopular: false,
  },
  {
    name: 'Orders',
    id: 'orders',
    price: { monthly: 'XX', annually: 'XXX' },
    description: "Manage your customer's orders",
    features: [
      'Visualize all your orders in a table',
      'Get real time information about your orders in delivery',
      'Get statistics about your orders',
    ],
    mostPopular: true,
  },
  {
    name: 'Stock',
    id: 'stock',
    price: { monthly: 'XX', annually: 'XXX' },
    description: 'Follow the evolution of your stock',
    features: ['Real time stock', 'Macroscopic stock', 'Precise stock'],
    mostPopular: false,
  },
  {
    name: 'Bills',
    id: 'bills',
    price: { monthly: 'XX', annually: 'XXX' },
    description: 'Store all your bills safely in one place',
    features: ['Safe storage', 'All in one place', "You don't need to print them anymore"],
    mostPopular: false,
  },
]

export const Prices: React.FC = () => {
  const { t } = useTranslation()

  const [frequency, setFrequency] = useState<FrequencyType>(frequencyOptions[0])

  return (
    <div className="bg-white">
      <Meta title={t('Prices')} description={t('Discover our prices')} />
      <div className="mx-auto max-w-7xl py-16 px-6 sm:py-24 lg:px-8">
        <div className="text-center">
          <h2 className="text-lg font-semibold text-emerald-600">{t('Prices')}</h2>
          <p className="mt-1 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">
            {t('Buy only what you need')}
          </p>
          <p className="mx-auto mt-5 max-w-xl text-xl text-gray-500">
            {t('Choose the modules you need to develop your activity.')}
          </p>
        </div>
        <div className="mt-16 flex justify-center">
          <RadioGroup
            value={frequency}
            onChange={setFrequency}
            className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
          >
            <RadioGroup.Label className="sr-only">{t('Payment frequency')}</RadioGroup.Label>
            {frequencyOptions.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option}
                className={({ checked }) =>
                  classNames(
                    checked ? 'bg-emerald-600 text-white' : 'text-gray-500',
                    'cursor-pointer rounded-full px-2.5 py-1',
                  )
                }
              >
                <span>{t(option.label)}</span>
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 md:max-w-2xl md:grid-cols-2 lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-4">
          {tiers.map((tier) => (
            <div
              key={tier.id}
              className={classNames(
                tier.mostPopular ? 'ring-2 ring-emerald-600' : 'ring-1 ring-gray-200',
                'rounded-3xl p-8',
              )}
            >
              <h2
                id={tier.id}
                className={classNames(
                  tier.mostPopular ? 'text-emerald-600' : 'text-gray-900',
                  'text-lg font-semibold leading-8',
                )}
              >
                {t(tier.name)}
              </h2>
              <p className="mt-4 text-sm leading-6 text-gray-600">{t(tier.description)}</p>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-4xl font-bold tracking-tight text-gray-900">
                  {t('${{ amount }}', { amount: tier.price[frequency.value] })}
                </span>
                <span className="text-sm font-semibold leading-6 text-gray-600">{t(frequency.priceSuffix)}</span>
              </p>
              <button
                aria-describedby={tier.id}
                className={classNames(
                  tier.mostPopular
                    ? 'bg-emerald-600 text-white shadow-sm hover:bg-emerald-500'
                    : 'text-emerald-600 ring-1 ring-inset ring-emerald-200 hover:ring-emerald-300',
                  'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600',
                )}
              >
                {t('Subscribe')}
              </button>
              <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon className="h-6 w-5 flex-none text-emerald-600" aria-hidden="true" />
                    {t(feature)}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

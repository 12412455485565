import React from 'react'

import illustration from '@assets/images/undraw_page_not_found_re_e9o6.svg'

import { useTranslation } from 'react-i18next'

export const NotFound: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className="w-full flex flex-col items-center justify-center px-10 gap-6">
      <img className="max-h-96" src={illustration} alt="page not found illustration" />
      <h1>{t('This page does not exist')}</h1>
    </div>
  )
}

import React from 'react'

import { Layout } from '@components'
import { Home, NotFound, Prices, Softwares } from '@pages'

import { Route, Routes } from 'react-router-dom'

export const Router: React.FC = () => (
  <Routes>
    <Route element={<Layout />}>
      <Route index element={<Home />} />
      <Route path="softwares/*" element={<Softwares />} />
      <Route path="prices" element={<Prices />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  </Routes>
)

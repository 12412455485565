import React from 'react'

import { Button, ButtonVariant } from '@firgal/components'

import illustration from '@assets/images/undraw_farm_girl_dnpe.svg'
import { Meta } from '@components'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export const Home: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className="max-w-7xl mx-auto flex flex-col content-center lg:flex-row justify-between p-5 gap-6 my-auto">
      <Meta description={`${t('Simplify the management of your farm')} ${t('with Firgal')}`} />
      <main className="w-full lg:w-1/2 mt-10 mx-auto px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
        <div className="sm:text-center lg:text-left">
          <h1 className="text-4xl tracking-tight font-bold text-gray-900 sm:text-5xl sm:tracking-tight md:text-6xl md:tracking-tight">
            <span className="block xl:inline">{t('Simplify the management of your farm')}</span>{' '}
            <span className="block text-emerald-600 xl:inline">{t('with Firgal')}</span>
          </h1>
          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            {t('With Firgal, easily manage your farm')}
          </p>
          <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
            <div className="mt-3 sm:mt-0">
              <Link to="/softwares">
                <Button variant={ButtonVariant.PRIMARY} className="w-full px-8 py-3 md:py-4 md:px-10">
                  {t('Discover')}
                </Button>
              </Link>
            </div>
            <div className="mt-3 sm:mt-0 sm:ml-3">
              <a href="mailto:contact@firgal.com?subject=Firgal">
                <Button variant={ButtonVariant.SECONDARY} className={'w-full px-8 py-3 md:py-4 md:px-10'}>
                  {t('Contact us')}
                </Button>
              </a>
            </div>
          </div>
        </div>
      </main>
      <img className="w-full lg:w-1/2" src={illustration} alt="farmer illustration" />
    </div>
  )
}

import React, { useState } from 'react'

import { Image, Text, TextVariant, classNames } from '@firgal/components'

import clutches from '@assets/images/clutches.png'
import orders from '@assets/images/orders.png'

import { useTranslation } from 'react-i18next'
import { NavLink, useLocation } from 'react-router-dom'

type Module = {
  name: string
  description: string
  image?: string
}

const modules: Module[] = [
  {
    name: 'Orders',
    description:
      'Manage and track your farm orders with table visualization, real-time delivery updates, and order statistics.',
    image: orders,
  },
  {
    name: 'Clutches',
    description: "Handle your farm's clutches day by day, including managing henhouses and associated statistics.",
    image: clutches,
  },
  {
    name: 'Stock',
    description: 'Monitor the evolution of your stock in real-time and receive precise information about it.',
  },
  {
    name: 'Bills',
    description: 'Safely store all your bills in one place, eliminating the need for physical printouts.',
  },
]

export const Softwares: React.FC = () => {
  const { t } = useTranslation()

  const location = useLocation()

  const [selectedModule, setSelectedModule] = useState<Module>(modules[0])

  return (
    <section className="relative overflow-hidden bg-gradient-to-b from-white to-emerald-50 pt-20 pb-28 sm:py-32">
      <div className="max-w-2xl md:mx-auto md:text-center xl:max-w-none px-4 sm:px-0 ">
        <Text as="h2" variant={TextVariant.DARK_GREY} textAlignment="center">
          {t('What you need for your business.')}
        </Text>
        <Text as="p" variant={TextVariant.PRIMARY} textAlignment="center" className="mt-3 text-xl">
          {t('Each module allows you to manage your farm according to your needs.')}
        </Text>
      </div>
      <div className="overflow-hidden">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mt-16 grid grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:pt-0">
            <div className="-mx-4 overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
              <nav className="flex flex-wrap justify-center lg:block my-auto text-base leading-7 text-gray-600 lg:max-w-none">
                {modules.map((module) => (
                  <NavLink
                    key={module.name}
                    onClick={() => setSelectedModule(module)}
                    to={`./${module.name.toLowerCase()}`}
                  >
                    {({ isActive }) => (
                      <div
                        className={classNames(
                          isActive || (location.pathname === '/softwares' && module.name === 'Orders')
                            ? 'rounded-full lg:rounded-l-xl lg:rounded-r-none bg-black/10 lg:bg-black/5 lg:ring-1 lg:ring-inset lg:ring-black/5'
                            : '',
                          'px-4 py-1 lg:p-6 hover:cursor-pointer',
                        )}
                      >
                        <p className="font-semibold text-zinc-800">{t(module.name)}</p>
                        <p className="mt-2 hidden text-sm lg:block text-zinc-800">{t(module.description)}</p>
                      </div>
                    )}
                  </NavLink>
                ))}
              </nav>
              <p className="block text-sm lg:hidden text-zinc-800 text-center mx-6 my-8">
                {t(selectedModule.description)}
              </p>
            </div>
            <div className="lg:col-span-7">
              {selectedModule.image && (
                <Image
                  label={`${selectedModule.name} image`}
                  url={selectedModule.image}
                  className="rounded-xl shadow-xl ring-1 ring-gray-400/10 lg:w-[57rem]"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

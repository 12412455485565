import React from 'react'

import { Footer, Navbar } from '@firgal/components'

import { Banner } from '../pages/home/components/banner'
import { useTranslation } from 'react-i18next'
import { Link, Outlet } from 'react-router-dom'

export const Layout: React.FC = () => {
  const { t } = useTranslation()

  const navigation = [
    { label: t('Softwares'), href: '/softwares' },
    { label: t('Prices'), href: '/prices' },
    { label: t('Log in'), href: process.env.REACT_APP_APP_URL, className: 'text-emerald-600 hover:text-emerald-500' },
  ]

  return (
    <div className="flex flex-col bg-zinc-50 min-h-screen">
      <Banner />
      <div className="block">
        <Navbar linkComponent={Link} navigation={navigation} />
      </div>
      <Outlet />
      <div className="mt-auto">
        <Footer content={`© ${new Date().getFullYear()} - Firgal`} />
      </div>
    </div>
  )
}
